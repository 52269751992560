import React from 'react';
import PropTypes from 'prop-types';

class RemainingPostLoader extends React.Component {
  componentDidMount() {
    this.loadPosts();
  }

  componentDidUpdate() {
    this.loadPosts();
  }

  loadPosts = () => {
    const { loadRemainingPosts, hasRemainingPosts, isLoading } = this.props;
    if (isLoading) {
      return;
    }

    if (loadRemainingPosts && hasRemainingPosts) {
      this.props.loadRemainingPosts();
    }
  };

  render() {
    return null;
  }
}

RemainingPostLoader.propTypes = {
  loadRemainingPosts: PropTypes.func,
  hasRemainingPosts: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default RemainingPostLoader;
